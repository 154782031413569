<template>
    <layout>        

        <template v-slot:username>
            <v-form 
                class="pa-5"
                @submit.prevent
            >
                <v-text-field
                    label="Username"
                    placeholder="Enter username"
                    name="id"                                                
                    type="text"
                    color="primary"
                    v-model="formData.name"
                    clearable
                    autofocus
                    v-on:keyup="keyPress"
                    auto-complete="off"
                >
                    <v-icon
                        slot="prepend"
                        color="primary"
                    >
                        mdi-account
                    </v-icon>
                </v-text-field>
                <div class="text-right mt-4 mb-4">                                                
                    <v-btn 
                        color="primary"
                        @click="nextStep" 
                        dark
                        block
                    >NEXT</v-btn>
                </div>
            </v-form>
        </template>

        <template v-slot:password>
            <v-form 
                class="pa-5"
                @submit.prevent="submit"
            >
                <v-text-field
                    v-show="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :label="passwordLabel"
                    :placeholder="passwordPlaceholder"
                    :hint="passwordHint"
                    persistent-hint
                    @click:append="show = !show"                                                    
                    name="password"
                    v-model="formData.password"
                    clearable
                    autofocus
                    color="primary"
                >
                    <v-icon
                        slot="prepend"
                        color="primary"
                    >
                        mdi-key
                    </v-icon>
                </v-text-field>
            

                <div class="text-right mt-4  mb-4">
                    <v-btn 
                        color="primary"
                        @click="submit" 
                        dark
                        block
                     >
                        LOGIN
                     </v-btn>
                </div>
            </v-form>             
        </template>

    </layout>
</template>

<script>
import Layout from '../layouts/Login';
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
    name: 'LoginTermReports',
    components: {
        Layout
    },
    data: () => ({
        formData: {
            name: '',
            password: '',            
        },
        show: false,        
        passwordPlaceholder: 'Enter password',
        passwordLabel: 'Password',
        passwordHint: '',
        password: true,
    }),
    computed: {
        ...mapGetters({            
            primaryColor: 'app/getPrimaryColor',
            colorDarken: 'app/getColorDarken',            
            step: 'auth/getStep',
            authenticated: 'auth/getAuthenticated',
            resetPassword: 'auth/getResetPassword',                       
        }),        
        icon_color(){
             return this.primaryColor+' '+this.colorDarken;
        },
        title_css(){
            return  this.primaryColor+'--text text--'+this.colorDarken+' text-h5 text-left ';
        },
        themeColor(){
            return this.primaryColor + ' ' +this.colorDarken;
        }
    },
    methods: {
        ...mapActions({
            signIn: 'auth/signIn', 
            employee: 'auth/employee',
            getUser: 'auth/user',
            getUserPermissionList: 'app/getUserPermissionList',
            setAuthenticated: 'auth/setAuthenticated',
            userPermissions: 'auth/userPermissions',
            serverError: 'auth/serverError',          
        }),

        ...mapMutations({
            loginError: 'auth/setLoginErrors', 
            setStep: 'auth/setStep',
            setOverlay: 'auth/setOverlay',
            setUserName: 'auth/setUserName',
            setResetPassword: 'auth/setResetPassword',
            setPermissions: 'auth/setPermissions',
            setEmployeeId: 'auth/setEmployeeId',
            setCredentials: 'auth/setCredentials',
            setAuthenticated: 'auth/setAuthenticated',
            setEmployeeSignedIn: 'auth/setEmployeeSignedIn',
            setEmployeeInitials: 'auth/setEmployeeInitials',
            setUser: 'auth/setUser',           
        }),

        async nextStep () {
            this.setOverlay(true);
            this.setUserName(this.formData.name);
            if(this.formData.name === 'Admin'){
                sessionStorage.setItem('employeeSignedIn', 'Admin');
                sessionStorage.setItem('employeeInitials', 'A')                
                this.setEmployeeSignedIn('Admin');
                this.setEmployeeInitials('A');
                this.setOverlay(false);
                this.loginError('');
                this.setStep(2);
                return;
            }

            try {
                let user = await this.getUser();  
                this.setEmployeeId(user.data.employee_id);
                this.setEmployeeSignedIn(user.data.first_name + ' ' + user.data.last_name);
                this.setEmployeeInitials(user.data.first_name[0] + user.data.last_name[0]);
                sessionStorage.setItem('employeeSignedIn', user.data.first_name + ' ' + user.data.last_name);
                sessionStorage.setItem('employeeInitials', user.data.first_name[0] + user.data.last_name[0])  
                this.setOverlay(false);
                this.loginError('');
                // console.log(`Reset Password: ${user.data.password_reset}`)
                if(user.data.password_reset == 1){
                    this.setResetPassword(true);
                }
                else{
                    this.setResetPassword(false);                    
                }
                this.setStep(2); 

            } catch (error) {
                this.setOverlay(false);
                //console.log(error);
                //console.log(error.response);
                if(error.response){
                    this.loginError(error.response.data);
                }
                else{
                    this.loginError(`An Error has occured ( ${error})`)
                }
                
            }
            
            
        },

        async submit () {
            console.log('Authenticating...');
            this.setOverlay(true);
            this.setCredentials(this.formData); 
            try {
                const { data } = await this.signIn();
                sessionStorage.authenticated = data;
                sessionStorage.employeeId = data.employee_id ? data.employee_id : data.name;
                const user =  {
                    employee_id: data.employee_id ? data.employee_id : data.name
                }
                this.setUser(JSON.stringify(user));
                sessionStorage.setItem('user', JSON.stringify(user));
                console.log('Authenticated.')                
                this.setAuthenticated(true);
                this.loginError('');                
                //console.log(this.resetPassword);

                if(data.name !== 'Admin'){
                    const { data: dataPermissions } = await this.userPermissions();
                    console.log(dataPermissions);
                    sessionStorage.setItem('permissions', JSON.stringify(dataPermissions));
                    
                    if(this.resetPassword == 1){
                        this.setStep(3); 
                        this.setOverlay(false);  
                        return;                 
                    }
                }
                
                this.$router.replace('/app');
                
                            
            } catch (error) {
                if(error.response) {
                    console.log(error.response)
                    this.loginError(error.response.data.message);
                }
                else console.log(error);
                // this.serverError(error);
                
            }
            this.setOverlay(false);
        },

        keyPress (e) {
            if(e.keyCode === 13){
                this.nextStep();
            }
        },
        
    },
}
</script>